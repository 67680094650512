import { render, staticRenderFns } from "./LibaryBackground.vue?vue&type=template&id=5ddbbe9f&scoped=true&"
import script from "./LibaryBackground.vue?vue&type=script&lang=js&"
export * from "./LibaryBackground.vue?vue&type=script&lang=js&"
import style0 from "./LibaryBackground.vue?vue&type=style&index=0&id=5ddbbe9f&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ddbbe9f",
  null
  
)

component.options.__file = "LibaryBackground.vue"
export default component.exports